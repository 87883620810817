<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<div class="ele-form-search d-flexspabet ">
				<div>
					<el-button @click="dialogTableVisibleEdit=true" class="ele-btn-icon addbtn mb-20"
						v-if="permission.includes('sys:deliverySet:add')">添加配送设置</el-button>
				</div>
				<el-form :model="table.where" class="ele-form-search d-flex f-end"
					@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
					<el-form-item label="订单类型:" label-width="100px" class="w-200">
						<el-select v-model="table.where.type" clearable>
							<el-option v-for="item in orderType" :key="item.type" :value="item.type"
								:label="item.type_name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="省:" label-width="35px" class="w-150">
						<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
							placeholder='请选择省' clearable>
							<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="市:" label-width="35px" class="w-150">
						<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)"
							placeholder='请选择市' clearable>
							<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
						<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
							<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
							class="ele-btn-icon">搜索</el-button>
						<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
						
					</el-form-item>
				</el-form>
			</div>

			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="type_name" label="订单类型" show-overflow-tooltip min-width="80" />
					<el-table-column prop="config_area" label="所属地区" show-overflow-tooltip min-width="120" />
					<el-table-column prop="config_detail" label="设置内容" show-overflow-tooltip min-width="120" />
					<el-table-column prop="update_time" label="更新时间" show-overflow-tooltip min-width="160" />
					<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false"
								v-if="permission.includes('sys:deliverySet:view')">查看</el-link>
							<el-link @click="handleEdit(row)" icon="el-icon-edit" type="primary" :underline="false"
								v-if="permission.includes('sys:deliverySet:edit')">编辑</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 查看用户详情 -->
		<el-dialog v-dialogDrag title="查看跑腿配送设置详情" :visible.sync="dialogTableVisibleCheck" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="checkForm" label-width="170px">
					<div>
						<el-form-item label="地区：">
							<el-select v-model="checkForm.pname" @change="handleChangeProv1(checkForm.pname)"
								placeholder='请选择省' class="selectStyle mr-10 mb-20" clearable
								:disabled="checkForm.id?true:false">
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="checkForm.cname" @change="handleChangeCity1(checkForm.cname)"
								placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable
								:disabled="checkForm.id?true:false">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="checkForm.aname" @change="$forceUpdate(),handleDis(checkForm.aname)"
								placeholder="请选择区/县" class="selectStyle mr-10 mb-20" clearable
								:disabled="checkForm.id?true:false">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="配送最大接单量：:" prop="max_order_num">
						{{checkForm.max_order_num}} 个
					</el-form-item>
					<el-form-item label="订单类型：">
						{{checkForm.type_name}}
					</el-form-item>
					<el-form-item label="预估金额范围：">
						<div>{{checkForm.min_price}} 元 ~ {{checkForm.max_price}} 元</div>
					</el-form-item>
					<el-form-item label="基础跑腿配送费：" prop="area_legwork_price">
						{{checkForm.area_legwork_price}} 元
					</el-form-item>
					<el-form-item label="基础配送距离：" prop="area_legwork_distance">
						{{checkForm.area_legwork_distance}} 公里
					</el-form-item>
					<el-form-item label="基础配送时间：" prop="base_time">
						<!--                <el-date-picker v-model="table.where.create_time" type="date" placeholder="选择日期"></el-date-picker> 分钟-->
						{{checkForm.base_time}} 分钟
					</el-form-item>
					<el-form-item label="特殊天气的状态：" prop="weather_status">
						<div v-if="checkForm.weather_status==1">开启</div>
						<div v-else>关闭</div>
					</el-form-item>
					<el-form-item label="特殊天气的价格：" v-if="checkForm.weather_status==1">
						<div>{{checkForm.weather_price}} 元</div>
					</el-form-item>
					<el-form-item label="距离附加费：">
						<div v-if="checkForm.distance_con">
							<div v-for="option in checkForm.distance_con" :key="option.id">
								{{option.start}} 公里 ~ {{option.end}} 公里，附加金额 {{option.price}} 元
							</div>
						</div>
						<div v-else>/</div>
					</el-form-item>
					<el-form-item label="特殊时段费：">
						<div v-if="checkForm.time_con">
							<div v-for="option in checkForm.time_con" :key="option.id">
								{{option.start}} ~ {{option.end}} ，附加金额 {{option.price}} 元
							</div>
						</div>
						<div v-else>/</div>
					</el-form-item>
					<el-form-item label="重量附加费：">
						<div v-if="checkForm.weight_con">
							<div v-for="option in checkForm.weight_con" :key="option.id">
								{{option.start}} 千克 ~ {{option.end}} 千克 ，附加金额 {{option.price}} 元
							</div>
						</div>
						<div v-else>/</div>
					</el-form-item>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 编辑配送设置 -->
		<el-dialog v-dialogDrag :title="editForm.id?'编辑配送设置':'添加配送设置'" :visible.sync="dialogTableVisibleEdit"
			:destroy-on-close="true" :lock-scroll="false" @closed="editForm={}">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" label-width="150px" :rules="editRules">
					<div>
						<el-form-item label="所属区域：" v-if="editForm.id" prop="pid">
							<el-select v-model="editForm.pname" class="selectStyle mr-10 mb-20" clearable
								placeholder="请选择省" disabled v-if="editForm.pname">
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" class="selectStyle mr-10 mb-20" clearable
								placeholder="请选择市" disabled v-if="editForm.cname">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" class="selectStyle mb-20" clearable
								:class="(editForm.pname && editForm.cname)?'mr-10':''" placeholder="区/县" disabled
								v-if="editForm.aname">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="所属区域：" v-if="!editForm.id" prop="pid">
							<el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)"
								placeholder='请选择省' class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cid" @change="handleChangeCity1(editForm.cid)"
								class="selectStyle mr-10 mb-20" clearable placeholder="请选择市">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aid" @change="$forceUpdate(),handleDis(editForm.aid)"
								class="selectStyle mr-10 mb-20" clearable placeholder="区/县">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>

					<el-form-item label="配送最大接单量：" prop="max_order_num">
						<el-input v-model="editForm.max_order_num" placeholder="" clearable class="input163" /> 个
					</el-form-item>
					<el-form-item label="订单类型：" prop="type">
						<el-select class="input163" v-model="editForm.type" placeholder="请选择订单类型" clearable>
							<el-option v-for="item in orderType" :value="item.type" :key="item.type"
								:label="item.type_name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="editForm.type == 3 || editForm.type == 4" label="配送调度时间：" prop="set_aside_time">
						<el-input v-model="editForm.set_aside_time" placeholder="" clearable class="input163" /> 分钟
					</el-form-item>
					<el-form-item v-if="editForm.type == 1" label="预估金额范围：" prop='min_price'>
						<el-input v-model="editForm.min_price" placeholder="请输入最小预估金额(元)" clearable class="input216" /> ~
						<el-input v-model="editForm.max_price" placeholder="请输入最大预估金额(元)" clearable class="input216" /> 元
						<span class='redColor'>（仅对跑腿-帮我买订单类型有效）</span>
					</el-form-item>
					<el-form-item label="基础跑腿配送费：" prop="area_legwork_price">
						<el-input v-model="editForm.area_legwork_price" placeholder="" clearable class="input163" /> 元
					</el-form-item>
					<el-form-item label="基础配送距离：" prop="area_legwork_distance">
						<el-input v-model="editForm.area_legwork_distance" placeholder="" clearable class="input163" />
						公里
					</el-form-item>
					<el-form-item label="基础配送时间：" prop="base_time">
						<el-input v-model="editForm.base_time" placeholder="" clearable class="input163" /> 分钟
						<span class='redColor'>（即跑腿配送必送达的最大时长）</span>
					</el-form-item>
					<el-form-item label="特殊天气的状态：" prop="weather_status">
						<el-radio-group v-model="editForm.weather_status"
							@change="weather_status_chahge(editForm.weather_status)">
							<el-radio label="1">开启</el-radio>
							<el-radio label="0">关闭</el-radio>
						</el-radio-group>
						<span v-if="editForm.weather_status==='1'">
							<el-input v-model="editForm.weather_price" oninput="value=value.replace(/[^0-9.]/g,'')"
								placeholder="请输入附加金额" clearable class="input163 ml-20" /> 元
						</span>
					</el-form-item>
					<el-form-item label="距离附加费：">
						<div v-for="(option,index) in editForm.distance_con" :key="option.id">
							<el-input v-model="option.start" placeholder="请输入距离(公里)" clearable class="input163 mb-20" /> ~
							<el-input v-model="option.end" placeholder="请输入距离(公里)" clearable class="input163 mb-20" /> 公里 
							<el-input v-model="option.price" placeholder="请输入附加金额" clearable class="input163 mb-20" /> 元
							<i class=" el-icon-remove-outline" v-if="index !=0" 
								style="font-size: 20px;margin-left: 10px;color: red" @click="handleDel(index)"></i>
							<i class=" el-icon-circle-plus-outline" v-if="index+1 ==editForm.distance_con.length"
								style="margin-left: 10px;" @click="handleAdd(index)"></i>
						</div>
					</el-form-item>
					<el-form-item label="特殊时段费：">
						<div v-for="(option1,index1) in editForm.time_con" :key="option1.id">
							<el-input v-model="option1.start" placeholder="请输入时间(分钟)" clearable class="input163 mb-20" /> ~
							<el-input v-model="option1.end" placeholder="请输入时间(分钟)" clearable class="input163 mb-20" /> 分钟 
							<el-input v-model="option1.price" placeholder="请输入附加金额" clearable class="input163 mb-20" /> 元
							<i class=" el-icon-remove-outline" v-if="index1 !=0" 
								style="font-size: 20px;margin-left: 10px;color: red" @click="handleDel1(index1)"></i>
							<i class=" el-icon-circle-plus-outline" v-if="index1+1 ==editForm.time_con.length"
								style="margin-left: 10px;" @click="handleAdd1(index)"></i>
						</div>
					</el-form-item>
					<el-form-item label="重量附加费：">
						<div v-for="(option2,index2) in editForm.weight_con" :key="option2.id">
							<el-input v-model="option2.start" placeholder="请输入重量(千克)" clearable class="input163 mb-20" /> ~
							<el-input v-model="option2.end" placeholder="请输入重量(千克)" clearable class="input163 mb-20" /> 千克 
							<el-input v-model="option2.price" placeholder="请输入附加金额" clearable class="input163 mb-20" /> 元
							<i class=" el-icon-remove-outline" v-if="index2 !=0" 
								style="font-size: 20px;margin-left: 10px;color: red" @click="handleDel2(index2)"></i>
							<i class=" el-icon-circle-plus-outline" v-if="index2+1 ==editForm.weight_con.length"
								style="margin-left: 10px;" @click="handleAdd2(index)"></i>
						</div>
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="closeDialog">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
	<Footer></Footer>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	// import uploadImage from '@/components/uploadImage';
	import Footer from "../../../components/footer"

	export default {
		name: "SysUser",
		components: {
			Footer
		},
		data() {
			return {
				// list: [{
				// 	id: 1,
				// 	show: true,
				// 	start: '',
				// 	price: '',
				// 	end: '',
				// }],
				// list1: [{
				// 	id: 1,
				// 	show: true,
				// 	start: '',
				// 	price: '',
				// 	end: '',
				// }],
				// list2: [{
				// 	id: 1,
				// 	show: true,
				// 	start: '',
				// 	price: '',
				// 	end: '',
				// }],
				table: {
					url: '/Legwork/legworkList',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				editForm: {
					distance_con: [{
						start: '',
						price: '',
						end: '',
					}],
					time_con: [{
						time_start: '',
						time_price: '',
						time_end: '',
					}],
					weight_con: [{
						weight_start: '',
						weight_price: '',
						weight_end: '',
					}],
				}, // 表单数据
				editForm1: {
					distance_con: [{
						start: '',
						price: '',
						end: '',
					}],
					time_con: [{
						time_start: '',
						time_price: '',
						time_end: '',
					}],
					weight_con: [{
						weight_start: '',
						weight_price: '',
						weight_end: '',
					}],
				}, // 表单数据
				addDeliverySet: {},
				checkForm: {},
				editRules: { // 表单验证规则
					pid: [{
						required: true,
						message: '请选择省份',
						trigger: 'change'
					}],
					max_order_num: [{
						required: true,
						message: '请输入配送最大接单量',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择订单类型',
						trigger: 'change'
					}],
					area_legwork_price: [{
						required: true,
						message: '请输入基础跑腿配送费',
						trigger: 'blur'
					}],
					min_price: [{
						required: true,
						message: '请输入预估金额范围',
						trigger: 'blur'
					}],
					area_legwork_distance: [{
						required: true,
						message: '请输入基础配送距离',
						trigger: 'blur'
					}],
					base_time: [{
						required: true,
						message: '请输入基础配送时间',
						trigger: 'blur'
					}],
					weather_status: [{
						required: true,
						message: '请选择特殊天气的状态',
						trigger: 'change'
					}],
					set_aside_time: [{
						required: true,
						message: '请输入配送调度时间',
						trigger: 'blur'
					}]
				},
				dialogTableVisibleCheck: false,
				dialogTableVisibleEdit: false,
				dialogTableVisibleUrgent: false,
				provArr: [],
				cityArr: [],
				districtArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				pid1: '',
				cid1: '',
				aid1: '',
				changePro: false,
				merchantId: 0,
				orderType: [{
					type: 1,
					type_name: '跑腿（帮我买）'
				}, {
					type: 2,
					type_name: '跑腿（取送件）'
				}, {
					type: 3,
					type_name: '超市'
				}, {
					type: 4,
					type_name: '叫餐'
				}],
				weatherStatusName: [{
					type: '1',
					status_name: '开启'
				}, {
					type: '0',
					status_name: '关闭'
				}]
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
		},
		computed: {
			...mapGetters(["permission"]),
		},
		// components: {uploadImage},
		mounted() {},
		methods: {
			closeDialog() {
				let item = {
					start: '',
					price: '',
					end: '',
				};
				let item1 = {
					time_start: '',
					time_price: '',
					time_end: '',
				}
				let item2 = {
					weight_start: '',
					weight_price: '',
					weight_end: '',
				}
				this.editForm = {
					distance_con: [item],
					time_con: [item1],
					weight_con: [item2],
				};
				this.dialogTableVisibleEdit = false
			},
			weather_status_chahge(status) {
				if (status == '0') {
					this.editForm.weather_price = '';
				}
			},
			handleInput(e, index) {
				if (index == 1) {
					this.distanceList.start = e
				} else if (index == 2) {
					this.distanceList.end = e
				} else if (index == 3) {
					this.distanceList.price = e
				}
			},
			handleAdd() {
				var obj = {
					start: '',
					price: '',
					end: '',
				}
				this.editForm.distance_con.push(obj)
			},
			handleDel(index) {
				this.editForm.distance_con.splice(index, 1);
			},
			handleDel1(index) {
				this.editForm.time_con.splice(index, 1);
			},
			handleDel2(index) {
				this.editForm.weight_con.splice(index, 1);
			},
			handleAdd1() {
				let item1 = {
					time_start: '',
					time_price: '',
					time_end: '',
				}
				this.editForm.time_con.push(item1)
			},
			handleAdd2() {
				let item2 = {
					weight_start: '',
					weight_price: '',
					weight_end: '',
				}
				this.editForm.weight_con.push(item2)
			},
			dropClick(command, row) {
				if (command === 'handlecheck') { // 查看用户信息
					this.handlecheck()
				} else if (command === 'handleEdit') { //修改用户信息
					this.handleEdit(row)
				}
			},
			/**
			 *修改用户信息
			 **/
			handleEdit(row) {
				this.dialogTableVisibleEdit = true
				let item = {
					start: '',
					price: '',
					end: '',
				};
				let item1 = {
					time_start: '',
					time_price: '',
					time_end: '',
				}
				let item2 = {
					weight_start: '',
					weight_price: '',
					weight_end: '',
				}
				if (row.distance_con == '') {
					row.distance_con = [];
					row.distance_con.push(item)
				}
				if (row.time_con == '') {
					row.time_con = [];
					row.time_con.push(item1)
				}
				if (row.weight_con == '') {
					row.weight_con = [];
					row.weight_con.push(item2)
				}
				this.editForm = row;
			},
			/**
			 * 保存编辑
			 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});

						// var list = {},
						// 	list1 = {},
						// 	list2 = {}
						// var listArr = [],
						// 	list1Arr = [],
						// 	list2Arr = []
						// for (let i = 0; i < this.list.length; i++) {
						// 	if (this.list[i].start && this.list[i].end && this.list[i].price) {
						// 		list.start = this.list[i].start
						// 		list.end = this.list[i].end
						// 		list.price = this.list[i].price
						// 		listArr.push(list)
						// 	}
						// }
						// for (let i = 0; i < this.list1.length; i++) {
						// 	if (this.list1[i].start && this.list1[i].end && this.list1[i].price) {
						// 		list1.start = this.list1[i].start
						// 		list1.end = this.list1[i].end
						// 		list1.price = this.list1[i].price
						// 		list1Arr.push(list1)
						// 	}
						// }
						// for (let i = 0; i < this.list2.length; i++) {
						// 	if (this.list2[i].start && this.list2[i].end && this.list2[i].price) {
						// 		list2.start = this.list2[i].start
						// 		list2.end = this.list2[i].end
						// 		list2.price = this.list2[i].price
						// 		list2Arr.push(list2)
						// 	}
						// }
						// if (this.editForm.distance_con) {
						// 	var distance_con = this.editForm.distance_con
						// 	this.editForm.distance_con = distance_con.concat(listArr)
						// } else {
						// 	this.editForm.distance_con = listArr
						// }
						// if (this.editForm.time_con) {
						// 	var time_con = this.editForm.time_con
						// 	this.editForm.time_con = time_con.concat(list1Arr)
						// } else {
						// 	this.editForm.time_con = list1Arr
						// }
						// if (this.editForm.weight_con) {
						// 	var weight_con = this.editForm.weight_con
						// 	this.editForm.weight_con = weight_con.concat(list2Arr)
						// } else {
						// 	this.editForm.weight_con = list2Arr
						// }

						this.$http.post('/Legwork/legworkSave', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 1) {
								this.dialogTableVisibleEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},

			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				let that = this
				this.changePro = false
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					that.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cid = ''
					this.editForm.aid = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.changePro = false
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aid = ''
				})
				}else{
					this.editForm.pid = ''
					this.editForm.aid = ''
				}
			},
			handleDis() {
				this.changePro = false
			},
			/**
			 /**
			 *查看商家配送设置信息
			 **/
			handlecheck(row) {
				this.dialogTableVisibleCheck = true
				if (row.weather_status == 0) {
					row.status = '关闭'
				} else(
					row.status = '开启'
				)
				this.checkForm = row;
			},
			/* 导出数据Excel */
			exportExcel() {
				let info = JSON.parse(JSON.stringify(this.table.where));
				this.$http
					// .get("/user/exportExcel", {'name':name,'phone':phone,'pid':pid,'cid':cid,'area_id':area_id})
					.get("/user/exportExcel", info)
					.then((res) => {
						let data = res.data;
						if (data.code == 0) {
							// 下载文件
							window.location.href = data.data;
							this.$message({
								type: "success",
								message: "导出成功",
							});
						} else {
							this.$message.error("导出失败");
						}
					})
			},
		}
	}
</script>

<style scoped>
	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	.el-icon-circle-plus-outline {
		color: #33CC99;
		font-size: 20px;
		margin-left: 30px
	}

	.redColor {
		color: red
	}

	/deep/.el-dialog {
		margin-top: 30px !important;
	}
</style>
